export const calculateStartDate = (range, multiplier = 1) => {
  const today = new Date();
  let date = new Date();
  if (range === "week") {
    date.setDate(today.getDate() - 6 * multiplier);
  } else if (range === "month") {
    date.setMonth(today.getMonth() - 1 * multiplier);
  } else if (range === "year") {
    date.setFullYear(today.getFullYear() - 1 * multiplier);
  }
  date.setHours(0, 0, 0, 0);
  return date;
};

export const featureNameMapping = {
  "broadcast-message": "Broadcast Message",
  PROSPECT_BY_POST_API_EXTERNAL: "Prospect by Post",
  "leads-from-suggestions": "Leads from Suggestions",
  "leads-from-peaple": "Leads from People",
  "leads-from-content": "Leads from Content",
  "leads-from-groups": "Leads from Groups",
  GAIN_RECIPROCITY_API_EXTERNAL: "Gain Reciprocity",
  CANCEL_FRIEND_REQUESTS_API_EXTERNAL: "Cancel Friend Requests",
  TAG_FOR_ATTENTION_API_EXTERNAL: "Tag for Attention",
  MANAGE_GROUP_API_EXTERNAL: "Manage Group",
  CANCEL_GROUP_INVITE_API_EXTERNAL: "Cancel Group Invite",
};
export const reverseFeatureNameMapping = {
  "Broadcast Message": "broadcast-message",
  "Prospect by Post": "PROSPECT_BY_POST_API_EXTERNAL",
  "Leads from Suggestions": "leads-from-suggestions",
  "Leads from People": "leads-from-peaple",
  "Leads from Content": "leads-from-content",
  "Leads from Groups": "leads-from-groups",
  "Gain Reciprocity": "GAIN_RECIPROCITY_API_EXTERNAL",
  "Cancel Friend Requests": "CANCEL_FRIEND_REQUESTS_API_EXTERNAL",
  "Tag for Attention": "TAG_FOR_ATTENTION_API_EXTERNAL",
  "Manage Group": "MANAGE_GROUP_API_EXTERNAL",
  "Cancel Group Invite": "CANCEL_GROUP_INVITE_API_EXTERNAL",
};

export const featureMappings = {
  'broadcast-message': ['totalMessageSentCount'],
  'PROSPECT_BY_POST_API_EXTERNAL': ['totalReplyCount', 'totalMessageSentCount'],
  'leads-from-suggestions': ['totalRequestSentCount'],
  'leads-from-peaple': ['totalRequestSentCount'],
  'leads-from-content': ['totalRequestSentCount'],
  'leads-from-groups': ['totalRequestSentCount'],
  'GAIN_RECIPROCITY_API_EXTERNAL': ['totalReactionsSentCount'],
  'CANCEL_FRIEND_REQUESTS_API_EXTERNAL': ['totalRequestsCancelledCount'],
  'TAG_FOR_ATTENTION_API_EXTERNAL': ['totalPeopleTagCount'],
  'MANAGE_GROUP_API_EXTERNAL': ['totalGoogleSheetUsersCount'],
  'CANCEL_GROUP_INVITE_API_EXTERNAL': ['totalInviteCancelCount'],
};

export const userSpecificFeatureMapping = {
  'broadcast-message': ['messageSentCount'],
  'PROSPECT_BY_POST_API_EXTERNAL': ['replyCount', 'messageSentCount'],
  'leads-from-suggestions': ['sentRequestCount'],
  'leads-from-peaple': ['sentRequestCount'],
  'leads-from-content': ['sentRequestCount'],
  'leads-from-groups': ['sentRequestCount'],
  'GAIN_RECIPROCITY_API_EXTERNAL': ['reactionSentCount'],
  'CANCEL_FRIEND_REQUESTS_API_EXTERNAL': ['requestsCancelledCount'],
  'TAG_FOR_ATTENTION_API_EXTERNAL': ['peopleTagCount'],
  'MANAGE_GROUP_API_EXTERNAL': ['googleSheetUsersCount'],
  'CANCEL_GROUP_INVITE_API_EXTERNAL': ['inviteCancelCount'],
};
export const formatDateWithoutTime = (date) => {
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${year}-${month}-${day}`;
};
export const generateDateRange = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= new Date(endDate)) {
    dates.push(formatDateWithoutTime((new Date(currentDate))));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};
